import { api, getTracksourceReportv2 } from 'api/report';
import Pagination from '@/components/pagination';
import optionData from '@/assets/js/optionData';
import GroupSelect from '@/components/GroupSelect';
import dateUtils from '@/utils/dateutils.js';
import { filterObject } from 'utils/utils';
import { fetchCountryList } from '@/api/DDJ/v3/country';
import qs from 'querystring';
import { fetchAdxList } from 'api/DDJ/v3/dmpFilter';
var clickTimer = null;
export default {
  components: {
    GroupSelect,
    Pagination,
  },
  data() {
    return {
      // 筛选条件
      filter: {
        date: [],
        fromHour: '',
        toHour: '',
        sorting: 'install',
        sortType: 'desc',
        columns: [],
      },
      // 筛选参数状态
      filterCheckedStatus: [],
      // 列表数据
      list: [],
      // 列表数据
      palist: [],
      // 下拉框列表
      options: {
        timezone: optionData.timezoneOption,
        offerIds: [],
        platform: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'ANDROID',
            value: 'ANDROID',
          },
          {
            label: 'IOS',
            value: 'IOS',
          },
        ],
        country: [],
        pdDomains: [],
        clickSourceApps: [],
        adxOptions: [],
      },
      // 加载
      loading: {
        offerIds: false,
        list: false,
        search: false,
      },
      // 设置
      config: {
        fullWidth: document.documentElement.clientWidth,
      },
      pages: {
        total: 0,
        pageParam: {
          page: 1,
          pageSize: 20,
        },
      },
      dataSourceList: [],
    };
  },
  mounted() {
    //日期默认选择
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24);
    this.filter.date = [];
    this.filter.date[0] = dateUtils.date2StrYMD(start);
    this.filter.date[1] = dateUtils.date2StrYMD(start);
    // 默认时间
    this.filter.fromHour = '00:00';
    this.filter.toHour = '23:00';
    // 默认时区
    this.filter.timezone = '+00:00';
    // 默认columns
    this.getCountry();
    this.getAdxList();
  },
  computed: {
    // 计算 InstallRejectRate
    calcInstallRejectRate: () => {
      return (insRejectTotal, installs) => {
        // 规则 如果两个参数都为0的情况 不进行计算
        if (insRejectTotal == 0 && installs == 0) {
          return 0;
        } else {
          return new Number((insRejectTotal / (insRejectTotal + installs)) * 100).toFixed(2) + '%';
        }
      };
    },
  },
  watch: {
    filterCheckedStatus: function() {
      this.$nextTick(() => {
        this.$refs.trackSourceTableRef.doLayout();
      });
    },
    'filter.columns': function(v) {
      // this.filter.sorting = v.length > 0 ? 'date' : 'rejectionTotal';
      this.filter.sorting = v.length > 0 ? 'install' : 'install';
      this.filter.sortType = 'desc';
    },
  },
  methods: {
    detailClick(row) {
      if (clickTimer) {
        window.clearTimeout(clickTimer);
        clickTimer = null;
      }
      var that = this;
      clickTimer = window.setTimeout(function() {
        that.$nextTick(() => {
          const { href } = that.$router.resolve({
            path: '/offer/detail',
            query: {
              offerId: row.offerId,
              country: row.countries,
              os: row.platforms,
            },
          });
          window.open(href, '_blank');
        });
      }, 300);
    },

    //查询adx列表
    getAdxList() {
      fetchAdxList().then((res) => {
        this.options.adxOptions = res.result;
      });
    },
    // 国家
    getCountry() {
      fetchCountryList().then((res) => {
        this.options.country = res.result;
      });
    },

    tableRowClassName({ rowIndex }) {
      if (rowIndex > 0 && rowIndex === this.list.length - 1) {
        return 'bgdfdfdf';
      }
    },
    // 拼接filter数据
    filterDataSet: function(e, page) {
      let param = {
        fromDate: this.filter.date[0],
        toDate: this.filter.date[1],
      };
      // 重置翻页
      if (page && page !== '') {
        this.pages.pageParam.page = page;
      }
      if (this.filter.fromHour && this.filter.toHour) {
        param.fromHour = this.filter.fromHour.substring(0, 2);
        param.toHour = this.filter.toHour.substring(0, 2);
      } else {
        this.$message.error('Hour 选项不能为空！');
        return;
      }

      if (this.filter.timezone) {
        param.timezone = this.filter.timezone;
      }
      if (this.filter.offerIds) {
        param.offerIds = this.filter.offerIds;
      }

      if (this.filter.countries) {
        param.countries = this.filter.countries;
      }
      if (this.filter.pkgs) {
        param.pkgs = this.filter.pkgs;
      }
      if (this.filter.data_source_id) {
        param.data_source_id = this.filter.data_source_id;
      }
      if (this.filter.sorting) {
        param.sorting = this.filter.sorting;
      }
      if (this.filter.sortType) {
        param.sortType = this.filter.sortType;
      }
      if (this.filter.offerTypes?.length > 0) {
        param.offerTypes = this.filter.offerTypes.toString();
      }

      if (this.filter.clickSourceApps) {
        param.clickSourceApps = this.filter.clickSourceApps;
      }

      if (this.filter.adxs?.length > 0) {
        param.adxs = this.filter.adxs.toString();
      }
      param.columns = this.filter.columns.concat(this.filterCheckedStatus).toString();
      if (param.columns.indexOf('affiliate_id') > -1) {
        param.columns = param.columns + ',company';
      }
      if (e === 'Search') {
        this.searchListClick(param);
      }
      if (e === 'Download') {
        this.exportExcelFileClick(param);
      }
    },
    // 筛选
    searchListClick(param) {
      let par = {
        ...this.pages.pageParam,
        ...param,
      };
      this.loading.list = true;
      this.loading.search = true;
      getTracksourceReportv2(par)
        .then((res) => {
          this.loading.list = false;
          this.loading.search = false;
          if (res.code === 200 && res.success === true) {
            let result = res.result || [];
            if (result.length === 0) {
              this.list = [];
              return;
            }
            this.list = [];
            result[result.length - 1].date = 'total';
            this.list = result;
            this.pages.total = res.total;
          } else {
            this.$message.error(res.message);
            this.list = [];
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$message.error('查询失败！' + error.toString());
          this.loading.list = false;
          this.loading.search = false;
        });
    },
    // 导出
    exportExcelFileClick(param) {
      // console.log(param);
      if (param) {
        param = filterObject(param);
        let strParam = qs.stringify(param);
        window.open(`${api.DOWNLOAD_TRACKSOURCE_REPORTV2}?${strParam}`, '_blank');
      } /*else {
        this.$message.error('columns不能为空！');
      }*/
    },
    // 更改每页显示数
    handleSizeChange(size) {
      // console.log(size);
      this.pages.pageParam.pageSize = size;
      this.handleCurrentChange(1); // 更改每页显示数以后页码重置为0
    },
    // 点击数字翻页
    handleCurrentChange(currentPage) {
      this.pages.pageParam.page = currentPage;
      this.filterDataSet('Search');
    },
    // 排序监听
    sortChange(column) {
      // console.log(column);
      if (column.prop !== null && column.order !== null) {
        this.filter.sorting = column.prop;
        this.filter.sortType = column.order === 'ascending' ? 'asc' : 'desc';
      } else {
        this.filter.sorting = '';
        this.filter.sortType = '';
      }
      this.filterDataSet('Search');
    },

    checkIn(column) {
      let checked = false;
      this.filterCheckedStatus.forEach((item) => {
        if (item === column) {
          checked = true;
        }
      });
      return checked;
    },
    checkDate(column) {
      return this.filter.columns.indexOf(column) > -1;
    },
  },
};
